import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
//import { cdn } from "../../authConfig";
import { RefreshIcon, ChevronRightIcon } from "@heroicons/react/solid";
import Filter from "../../components/filter";
import Seo from "../../components/seo";
//import { useLocation } from "@reach/router";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import escapeStringRegexp from "escape-string-regexp";

const VideoIndexPage = ({ data }) => {
  //const location = useLocation();
  // const cdnUrl =
  //   location.hostname === "www.mizuno-ch.cn" ? cdn.url_cn : cdn.url;

  const allNodes = data.allVideoJson.nodes;
  const [nodes, setNodes] = useState(allNodes);

  const allYears = [
    ...new Set(
      allNodes
        .filter((node) => node.created)
        .map((node) => node.created.substring(0, 4))
    ),
  ];
  const [years, setYears] = useState(allYears);
  const allTags = [
    ...new Set(
      allNodes
        .filter((node) => node.category)
        .map((node) => node.category.toString())
    ),
  ];
  const [tags, setTags] = useState(allTags);
  const allTypes = [
    {
      id: 1,
      key: "free",
      value: "無料",
    },
    {
      id: 2,
      key: "member",
      value: "会員",
    },
    {
      id: 3,
      key: "option",
      value: "オプション会員",
    },
  ];
  const [types, setTypes] = useState(allTypes);

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const onInput = (e) => {
    setSearchKeyword(e.currentTarget.value);
  };

  useEffect(() => {
    if (selectedYear || selectedTag || selectedType || searchKeyword !== "") {
      setNodes(
        allNodes.filter((node) => {
          return (
            (selectedYear
              ? node.created.substring(0, 4) === selectedYear
              : true) &&
            (selectedTag ? node.category.toString() === selectedTag : true) &&
            (selectedType
              ? node.type === allTypes.find((t) => t.value === selectedType).key
              : true) &&
            (searchKeyword !== ""
              ? new RegExp(
                  escapeStringRegexp(searchKeyword.toLowerCase())
                ).test(
                  node.title.toLowerCase() +
                    (node.description ? node.description.toLowerCase() : "")
                )
              : true)
          );
        })
      );
    } else {
      setNodes(allNodes);
    }
    setYears([
      ...new Set(
        allNodes
          .filter((node) => {
            return (
              (selectedTag ? node.category.toString() === selectedTag : true) &&
              (selectedType
                ? node.type ===
                  allTypes.find((t) => t.value === selectedType).key
                : true) &&
              (searchKeyword !== ""
                ? new RegExp(
                    escapeStringRegexp(searchKeyword.toLowerCase())
                  ).test(
                    node.title.toLowerCase() +
                      (node.description ? node.description.toLowerCase() : "")
                  )
                : true)
            );
          })
          .filter((node) => node.created)
          .map((node) => node.created.substring(0, 4))
      ),
    ]);
    setTags([
      ...new Set(
        allNodes
          .filter((node) => {
            return (
              (selectedYear
                ? node.created.substring(0, 4) === selectedYear
                : true) &&
              (selectedType
                ? node.type ===
                  allTypes.find((t) => t.value === selectedType).key
                : true) &&
              (searchKeyword !== ""
                ? new RegExp(
                    escapeStringRegexp(searchKeyword.toLowerCase())
                  ).test(
                    node.title.toLowerCase() +
                      (node.description ? node.description.toLowerCase() : "")
                  )
                : true)
            );
          })
          .filter((node) => node.category)
          .map((node) => node.category.toString())
      ),
    ]);
    const _types = [
      ...new Set(
        allNodes
          .filter((node) => {
            return (
              (selectedTag ? node.category.toString() === selectedTag : true) &&
              (selectedYear
                ? node.created.substring(0, 4) === selectedYear
                : true) &&
              (searchKeyword !== ""
                ? new RegExp(
                    escapeStringRegexp(searchKeyword.toLowerCase())
                  ).test(
                    node.title.toLowerCase() +
                      (node.description ? node.description.toLowerCase() : "")
                  )
                : true)
            );
          })
          .filter((node) => node.type)
          .map((node) => node.type)
      ),
    ];
    setTypes(
      _types.map((t) => ({
        id: allTypes.find((a) => a.key === t).id,
        key: t,
        value: allTypes.find((a) => a.key === t).value,
      }))
    );
  }, [selectedYear, selectedTag, selectedType, searchKeyword]);

  return (
    <Layout>
      <Seo title="中国ビジネス解説動画" />
      <div className="flex flex-col justify-center items-center bg-cyan-50 text-gray-600 font-sans">
        <h1 className="md:text-2xl text-lg font-medium text-center">
          <StaticImage
            src="../../images/video.webp"
            alt="本コンテンツは、弊社グループ代表水野真澄が共同通信グループNNAで連載中の
          「中国ビジネス講座」の2021年1月以降 の全記事を分かりやすく解説、
          更には、中国の制度（外貨管理、税関、税務、会社管理制度など）にスポットをあてたシリー ズや最新ホットトピックなど、
          中国ビジネスに役立つ内容を各回15分前後で解説する動画サービスです。
          法的根拠をもとに実務運用についても解説しており、最新かつ正確な情報をお届けしております。
          また、各動画にはPDF資料が付いており、社内研修にもご活用いただけます。"
          ></StaticImage>
        </h1>
      </div>
      <div className="bg-gray-100 text-gray-600">
        <nav className="container mx-auto p-1 md:p-3 font-sans">
          <ol className="list-reset flex items-center text-sm">
            <li>
              <Link to="/" className="text-blue-600 hover:text-blue-700">
                ホーム
              </Link>
            </li>
            <li className="mx-1">
              <ChevronRightIcon className="w-4 h-4" />
            </li>
            <li>中国ビジネス解説動画</li>
          </ol>
        </nav>
      </div>
      <div className="container mx-auto max-w-5xl px-5 md:px-10 py-10 md:py-20 text-sm md:text-base">
        <div className="mb-5 md:flex grid grid-cols-1 gap-3 justify-end items-center font-sans">
          <div className="flex gap-2">
            <p className="text-gray-600 text-sm">{`${nodes.length}件表示/${allNodes.length}件`}</p>
            {tags.length > 0 || years.length > 0 ? (
              <button
                onClick={() => {
                  setSelectedYear(null);
                  setSelectedTag(null);
                  setSelectedType(null);
                  setSearchKeyword("");
                }}
              >
                <RefreshIcon className="w-4 h-4 text-gray-400" />
              </button>
            ) : null}
          </div>
          <form className="md:w-48 w-full">
            <input
              className="SearchInput focus:ring-cyan-400 focus:ring-2 focus:border-cyan-400 w-full border-gray-200 placeholder-gray-600"
              type="search"
              placeholder="検索"
              aria-label="検索"
              onInput={onInput}
              value={searchKeyword}
            />
          </form>
          {tags.length > 0 ? (
            <Filter
              selected={selectedTag}
              setSelected={setSelectedTag}
              options={tags}
              msgSelect="タグを選択"
              width="md:w-56 w-full"
            />
          ) : null}
          {years.length > 0 ? (
            <Filter
              selected={selectedYear}
              setSelected={setSelectedYear}
              options={years.sort((a, b) => b - a)}
              msgSelect="年を選択"
            />
          ) : null}
          {types.length > 0 ? (
            <Filter
              selected={selectedType}
              setSelected={setSelectedType}
              options={types.sort((a, b) => a.id - b.id).map((t) => t.value)}
              msgSelect="閲覧タイプを選択"
              width="md:w-48 w-full"
            />
          ) : null}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-4">
          {nodes.map((node, index) => (
            <Link
              to={`${node.slug}`}
              key={index}
              className="hover:bg-cyan-100 border border-cyan-400 block relative font-sans"
            >
              <div className="absolute -top-[1px] -left-[1px] text-xs z-10">
                {node.type === "free" && (
                  <div className="bg-teal-500 text-white px-2 py-1">無料</div>
                )}
                {node.type === "member" && (
                  <div className="bg-cyan-500 text-white px-2 py-1">会員</div>
                )}
                {node.type === "option" && (
                  <div className="bg-cyan-800 text-white px-2 py-1">
                    オプション会員
                  </div>
                )}
              </div>
              <GatsbyImage image={getImage(node.image)} alt={node.slug} />
              <div key={index} className="p-5">
                <div className="mb-3">{node.title}</div>
                <div className="text-sm mb-2">
                  {node.created}
                  {node.category ? " | " + node.category : null}
                </div>
                <div className="text-sm">{node.description}</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default VideoIndexPage;

export const query = graphql`
  query {
    allVideoJson(
      sort: { fields: [created, series, no], order: [DESC, DESC, DESC] }
      filter: { series: { ne: "seminar" } }
    ) {
      nodes {
        category
        created
        description
        title
        series
        slug
        no
        type
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
